<template>
  <div class="music-management">
		<vue-scrolling-table class="tbl-wrap">
			<template #thead>
				<tr>
					<th class="no">No</th>
					<th class="titleJa">曲名（かな）</th>
					<th class="titleKana">曲名（カナ）</th>
					<th class="titleEn">曲名（en）</th>
					<th class="thumbnail">サムネイル</th>
					<th class="status">状態</th>
					<th class="isrc">ISRC</th>
					<th class="releaseId">リリースID</th>
					<th class="releaseDate">リリース期間</th>
					<th class="lisence">ライセンス<br>保持者</th>
					<th class="storageUrl">StorageURL</th>
					<th class="link">LinkCore<br>URL</th>
					<th class="link">YouTube<br>URL</th>
					<th class="link">Tiktok<br>URL</th>
					<th class="link">Instagram<br>URL</th>
					<th class="updateDate">更新日時</th>
					<th class="updater">更新者</th>
				</tr>
			</template>
			<template #tbody>
				<tr v-for="music in musics" :key="music">
					<td class="no" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.no }}</label>
						<input type="number" @blur="blurInput" @change="changeInput($event, 'no')" :value="music.no" style="display: none;">
					</td>
					<td class="titleJa" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.titleJa }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'titleJa')" :value="music.titleJa" style="display: none;">
					</td>
					<td class="titleKana" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.titleKana }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'titleKana')" :value="music.titleKana" style="display: none;">
					</td>
					<td class="titleEn" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.titleEn }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'titleEn')" :value="music.titleEn" style="display: none;">
					</td>
					<td class="thumbnail dndImg"
								@dragenter="dragEnter"
								@dragleave="dragLeave"
								@dragover.prevent
								@drop.prevent="dropFile($event, music.id)"
								:class="{enter: isDragEnter}">
							<div class="imgContents" v-if="music.imgUrl">
								<img class="imgContentImg" :src="music.imgUrl" alt="">
							</div>
					</td>
					<!-- <td class="tentativeTitle" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.tentativeTitle }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'tentativeTitle')" :value="music.tentativeTitle" style="display: none;">
					</td> -->
					<td class="status" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.status }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'status')" :value="music.status" style="display: none;">
					</td>
					<td class="isrc" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.isrc }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'isrc')" :value="music.isrc" style="display: none;">
					</td>
					<td class="releaseId" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.releaseId }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'releaseId')" :value="music.releaseId" style="display: none;">
					</td>
					<td class="releaseDate" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.releaseDateFmt }}</label>
						<input type="date" @blur="blurInput" @change="changeInput($event, 'releaseDate')" :value="music.releaseDate" style="display: none;">
					</td>
					<td class="lisence" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.lisence }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'lisence')" :value="music.lisence" style="display: none;">
					</td>
					<td class="storageUrl" @dblclick="labelDblclick($event, music.id)">
						<label class="storageUrlLabel" @dblclick="labelDblclick($event, music.id)">{{ music.storageUrl }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'storageUrl')" :value="music.storageUrl" style="display: none;">
					</td>
					<td class="link" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.linkCore }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'linkCore')" :value="music.linkCore" style="display: none;">
					</td>
					<td class="link" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.youtube }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'youtube')" :value="music.youtube" style="display: none;">
					</td>
					<td class="link" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.tiktok }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'tiktok')" :value="music.tiktok" style="display: none;">
					</td>
					<td class="link" @dblclick="labelDblclick($event, music.id)">
						<label @dblclick="labelDblclick($event, music.id)">{{ music.instagram }}</label>
						<input type="text" @blur="blurInput" @change="changeInput($event, 'instagram')" :value="music.instagram" style="display: none;">
					</td>
					<td class="updateDate">
						<label>{{ music.updateDateFmt }}</label>
					</td>
					<td class="updater">
						<label>{{ music.updater }}</label>
					</td>
				</tr>
			</template>
		</vue-scrolling-table>

    <img @click="newRow" class="new" src="../../assets/icons/plus.svg" alt="">
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, doc, getDoc, getDocs, addDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

export default {
  name: 'RadioRegist',
	components: {
		VueScrollingTable
	},
  props: {
  },
  data() {
    return {
      touchId: null,
      musics: [],
      userUid: null,
      updater: null,
			isDragEnter: false,

			columns: [
				{id:'id', title:'ID' },
				{id:'firastName', title:'First Name' },
				{id:'lastName', title:'Last Name' },
				{id:'mail', title:'MAIL' },
				{id:'tel', title:'TEL' }
			],
			items: [
				{
					id: 1,
					firastName: 'Howard',
					lastName: 'Ramirez',
					mail: 'hoge@hoge.com',
					tel: '000-000-0000'
				},
				{
					id: 2,
					firastName: 'Howard',
					lastName: 'Ramirez',
					mail: 'hoge@hoge.com',
					tel: '000-000-0000'
				},
				{
					id: 3,
					firastName: 'Howard',
					lastName: 'Ramirez',
					mail: 'hoge@hoge.com',
					tel: '000-000-0000'
				},
				{
					id: 4,
					firastName: 'Howard',
					lastName: 'Ramirez',
					mail: 'hoge@hoge.com',
					tel: '000-000-0000'
				}
			]
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      } else {
        this.userUid = user.uid;

		const ref = doc(firebase.db, "Members", this.userUid);
		getDoc(ref).then(snapshot => {
			if (snapshot.exists()) {
				let data = snapshot.data();
				if (data.admin) {
					this.updater = data.lastname ? data.lastname : data.firstname;
				} else {
					this.$router.push("/");
				}
			}
		});
      }
    });
  },
  mounted() {
    document.getElementById("footer").style.display = "none";

    document.getElementById("account").style.color = "white";
    document.getElementsByClassName("menu").forEach(x => {
      x.style.color = "black";
    });

    // document.getElementsByClassName("music-management")[0].style.height = window.innerHeight + "px";


    this.onloadFunc();  
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
    labelDblclick(e, id) {
      e.target.getElementsByTagName("label")[0].style.display = "none";
      e.target.getElementsByTagName("input")[0].style.display = "grid";
      e.target.getElementsByTagName("input")[0].focus();
      this.touchId = id;
    },
    blurInput(e) {
      e.target.style.display = "none";
      e.target.previousElementSibling.style.display = "grid";
    },
    async changeInput(e, key) {
      const updateDate = new Date();
      let data = { updater: this.updater, updateDate: updateDate };
      const value = e.target.value;
      data[key] = value;
      if (this.touchId.indexOf("temporary") >= 0) {
        // 新規
        const db = collection(firebase.db, "Musics");
        const docRef = await addDoc(db, data);
        let music = this.musics.find(x => x.id == this.touchId);
        music[key] = value;
        if (key == "releaseDate") {
          let rDate = new Date(value);
          const from = this.formatDate(rDate);
          const to = this.formatDate(new Date(rDate.setFullYear(rDate.getFullYear() + 1)));
          music.releaseDateFmt = from + " - " + to;
        }
        music.updateDate = updateDate;
        music.updateDateFmt = this.formatDateTime(updateDate);
        music.updater = this.updater;
        music.id = docRef.id;

      } else {
        // 更新
        await updateDoc(doc(firebase.db, "Musics", this.touchId), data); 
        let music = this.musics.find(x => x.id == this.touchId);
        music[key] = value;
        if (key == "releaseDate") {
          let rDate = new Date(value);
          const from = this.formatDate(rDate);
          const to = this.formatDate(new Date(rDate.setFullYear(rDate.getFullYear() + 1)));
          music.releaseDateFmt = from + " - " + to;
        }
        music.updateDate = updateDate;
        music.updateDateFmt = this.formatDateTime(updateDate);
        music.updater = this.updater;

      }
      this.touchId = null;
      e.target.style.display = "none";
      e.target.previousElementSibling.style.display = "grid";
    },
    awaitFunc() {
      this.datas = [];
      this.onloadFunc();
    },
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Musics"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        console.log(data);
        data.id = doc.id;
        if (data.updateDate) {
          data.updateDateFmt = this.formatDateTime(this.toDate(data.updateDate));
        }
        if (data.releaseDate) {
          let rDate = new Date(data.releaseDate);
          const from = this.formatDate(rDate);
          const to = this.formatDate(new Date(new Date(rDate.setFullYear(rDate.getFullYear() + 1)).setDate(rDate.getDate() - 1)));
          data.releaseDateFmt = from + " - " + to;
        }
        this.musics.push(data);
      });
			this.musics.sort((a, b) => a.no - b.no);
    },
    toDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date;
    },
    formatDate(date) {
      return date.getFullYear() + "/"
          + (String(date.getMonth() + 1)).padStart(2, '0') + "/"
          + (String(date.getDate())).padStart(2, '0');
    },
    formatDateTime(date) {
      return date.getFullYear() + "/"
          + (String(date.getMonth() + 1)).padStart(2, '0') + "/"
          + (String(date.getDate())).padStart(2, '0') + " "
          + String(date.getHours()).padStart(2, '0') + ":"
          + String(date.getMinutes()).padStart(2, '0');
    },
    async deleteData() {
      const id = document.getElementById("dataid").innerText;
      if (id) {
        if (confirm("削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "Musics", id));
        }
        this.awaitFunc();
        alert("削除しました");
      }
    },
    newRow() {
      this.musics.push({ id: "temporary" + this.musics.length });
    },
		dragEnter() {
      this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    dropFile(event, id) {
      if (id.indexOf("temporary") >= 0) {
        return;
      }
      let music = this.musics.find(x => x.id == id);
      this.isDragEnter = false;
      let file = null;
      if (event.dataTransfer) {
        file = event.dataTransfer.files[0];
      } else {
        file = event.target.files[0];
      }

      if (!file) {
        return;
      }

      const fullPath = "thumbnails/" + file.name;
      const storage = getStorage();
      const storageRef = ref(storage, fullPath);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(ref(storage, snapshot.metadata.fullPath))
        .then((url) => {
					const updateDate = new Date();
					let data = { updater: this.updater, updateDate: updateDate };
					data.imgUrl = url;
					data.fullPath = fullPath;
					updateDoc(doc(firebase.db, "Musics", id), data)
					.then(() => {
            if (music.fullPath) {
              deleteObject(ref(storage, music.fullPath)).then(() => {
                music.imgUrl = url;
                music.fullPath = fullPath;
                music.updateDate = updateDate;
                music.updateDateFmt = this.formatDateTime(updateDate);
                music.updater = this.updater;
              }).catch((error) => {
                console.log(error);
              });
            } else {
              music.imgUrl = url;
              music.fullPath = fullPath;
              music.updateDate = updateDate;
              music.updateDateFmt = this.formatDateTime(updateDate);
              music.updater = this.updater;
            }
					}); 
        })
        .catch((error) => {
          console.log(error);
        });
      });
    },
  }
}
</script>

<style scoped>
.music-management {
	display: grid;
  font-family: "Kaisei Decol", serif;
	width: 98%;
  margin: 2vw auto;
}
.tbl-wrap {
  overflow-x: auto;
  font-size: 0.9rem;
	height: 30vw;
}
table {
	background-color: #ffffff38;
}
.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
	background-color: #ffffff38;
}
thead {
  background: linear-gradient(to bottom left, #ea437e, #f08a48);
}
th {
  text-align: center !important;
  vertical-align: middle;
}
tr {
  height: 4vw;
}
td {
  opacity: 0.8;
	vertical-align: middle;
}
label {
	display: grid;
	overflow: hidden;
}
input {
	width: 100%;
}
.new {
	width: 2vw;
	height: auto;
	position: fixed;
	right: 2vw;
	bottom: 3vw;
	background: black;
	border-radius: 50%;
}
.no {
  min-width: 3vw;
  max-width: 3vw;
}
.titleJa,
.titleKana,
.titleEn,
.tentativeTitle {
  min-width: 12vw;
  max-width: 12vw;
}
.thumbnail,
.lisence,
.updateDate {
  min-width: 6vw;
  max-width: 6vw;
}
.thumbnail img {
  width: 4vw;
  height: 4vw;
  object-fit: cover;
}
.isrc,
.releaseDate,
.releaseId {
  min-width: 8vw;
  max-width: 8vw;
}
.status,
.updater {
  min-width: 4vw;
  max-width: 4vw;
}
.storageUrl {
  min-width: 10vw;
  max-width: 10vw;
}
.storageUrlLabel {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	position: relative;
}
.link {
  min-width: 6vw;
  max-width: 6vw;
}
.scTable {
	color: black;
	background-color: #ea437e;
}
</style>
